import React from 'react'

import Layout from '../components/organisms/Layout/Layout'
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from '../components/atoms/SEO/SEO'

const Coaching = () => (
    <Layout>
        <SEO title="Imprint" description="All information is displayed without limit or warranty. All information is subject to change." />
        <PageWidth isContent={true}>
            <h1>Imprint</h1>
            <p>This domain and its subdomains is owned and operated by Karl Ludwig Weise, Grevenweg 80, 20537 Hamburg, Germany, office@ludwigweise.de, VAT ID: DE224733454.</p>
            <p>All information is displayed without limit or warranty. All information is subject to change. Offers are optional and not binding. Shown works, information and their design are protected under copyright law. All rights reserved. Replication only with written consent. Despite review I distance myself from linked pages.</p>
        </PageWidth>
    </Layout>
)

export default Coaching
